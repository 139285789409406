<template>
	<div class="pageview">
		<div class="pageviewtitle">
			<div class="titletab">
				<el-icon @click="backpath">
					<ArrowLeft />
				</el-icon>添加知识点
			</div>
		</div>
		<el-scrollbar class="dd" v-loading="loadingall">
			<div class="row" style="margin-top: 20px;">
				<div class="row-title">父级
				</div>
				<el-cascader style="width: 100%;" @change="parentchange" :options="treedata" :props="props" clearable />
			</div>
			<div class="row">
				<div class="row-title">知识点名称<el-tag type="danger" size="small">必填</el-tag>
				</div>
				<el-input v-model="addjson.knowledgeName" placeholder="请输入要添加的知识点名称" clearable type="text"></el-input>
			</div>
			<div class="row">
				<div class="row-title">知识点描述<el-tag type="danger" size="small">必填</el-tag>
				</div>
				<el-input v-model="addjson.knowledgeDesc" placeholder="请输入要添加的知识点描述" clearable type="text"></el-input>
			</div>
			<div class="row">
				<div class="row-title">知识点分类<el-tag type="danger" size="small">必填</el-tag>
				</div>
				<el-select v-model="addjson.categoryId" style="width: 100%;" clearable>
					<el-option v-for="item in knowledgecategoreislist" :label="item.categoryName" :value="item.id">
					</el-option>
				</el-select>
			</div>

			<div class="row" style="margin-top: 20px;">
				<div class="row-title">已选中的单元<el-tag type="danger" size="small">必选</el-tag>
					<el-button size="small" type="success" style="margin-left: 20px;" @click="showtable = true">选择单元
					</el-button>
				</div>
				<el-tag v-for="item in addjson.knowledgeAttributionRelationList" :key="item.id"
					@close="removeitem(item)" class="rowtag" closable>
					{{item.subject.subjectName}}-{{item.grade.gradeName}}-{{item.semester.semesterName}}-{{item.unitName}}
				</el-tag>
			</div>
			<div style="margin-top: 20px;">
				<el-button style="width: 200px;" type="primary" @click="submitadd" :loading="loading">添加</el-button>
			</div>
		</el-scrollbar>


		<el-dialog v-model="showtable" title="选择单元" width="70%">
			<div style="padding-bottom: 20px;">
				<el-select placeholder="请选择学科" @change="changetable" v-model="queryjson.subjectId" clearable>
					<el-option v-for="item in subjectslist" :label="item.subjectName" :value="item.id">
					</el-option>
				</el-select>
				<el-select placeholder="请选择年级" @change="changetable" v-model="queryjson.gradeId" clearable
					style="margin-left: 20px;">
					<el-option v-for="item in gradeslist" :label="item.gradeName" :value="item.id">
					</el-option>
				</el-select>
			</div>
			<el-table ref="tableref" @selection-change="tablechange" :row-key="getrowkey" v-loading="dyloading"
				:data="unitlist" style="width: 100%" stripe border>
				<el-table-column type="selection" :reserve-selection="true" />
				<el-table-column type="index" align="center" width="50" />
				<el-table-column align="center" prop="unitName" label="名称" />
				<el-table-column align="center" prop="grade.gradeName" label="年级" />
				<el-table-column align="center" prop="semester.semesterName" label="学期" />
				<el-table-column align="center" prop="subject.subjectName" label="学科" />
				<el-table-column align="center" prop="press.pressName" label="出版社" />
			</el-table>
			<div class="elpagination">
				<el-pagination background layout="prev, pager, next" @current-change="pagechange"
					:page-count="queryjson.pages" :current-page.sync="queryjson.pageNum" />
			</div>
		</el-dialog>

	</div>
</template>

<script>
	import {
		ArrowLeft
	} from '@element-plus/icons-vue'
	import {
		knowledgetree,
		knowledgecategoreislist,
		subjectslist,
		gradeslist,
		unitlist,
		knowledgeadd
	} from "../../util/api"
	export default {
		components: {
			ArrowLeft
		},
		data() {
			return {
				showtable: false,
				loadingall: false,
				dyloading: false,
				loading: false,
				treedata: null,
				props: {
					value: 'id',
					label: 'knowledgeName',
					children: 'children',
					expandTrigger: 'hover',
					checkStrictly: true
				},
				addjson: {
					knowledgeDesc: null, //知识点描述
					knowledgeName: null, //知识点名称
					parentId: null, //父级
					categoryId: null, //知识点分类ID
					knowledgeAttributionRelationList: []
				},
				queryjson: {
					gradeId: null,
					subjectId: null,
					pageNum: 1,
					pageSize: 10,
					pages: 0
				},
				knowledgecategoreislist: null, //知识点分类列表
				subjectslist: null, //学科列表
				gradeslist: null, //年级列表
				unitlist: null, //单元列表
			}
		},
		created() {
			this.gettree()
			this.getknowledgecategoreislist() //获取知识点分类列表
			this.getsubjectslist() //获取学科列表
			this.getgradeslist() //获取年级列表
			this.getunitlist()
		},
		methods: {
			submitadd() {
				if (!this.addjson.knowledgeName) {
					this.$message({
						type: 'warning',
						message: '请输入名称',
					})
				} else if (!this.addjson.knowledgeDesc) {
					this.$message({
						type: 'warning',
						message: '请输入描述',
					})
				} else if (!this.addjson.categoryId) {
					this.$message({
						type: 'warning',
						message: '请选择知识点分类',
					})
				} else if (this.addjson.knowledgeAttributionRelationList.length <= 0) {
					this.$message({
						type: 'warning',
						message: '请选择单元',
					})
				} else {
					this.loading = true
					let newlist = []
					this.addjson.knowledgeAttributionRelationList.forEach((item) => {
						newlist.push({
							pressId: item.pressId,
							gradeId: item.gradeId,
							semesterId: item.semesterId,
							unitId: item.id,
							subject: {
								subjectName: item.subject.subjectName,
							},
							grade: {
								gradeName: item.grade.gradeName
							},
							semester: {
								semesterName: item.semester.semesterName
							},
							unitName: item.unitName,
							id: item.id
						})
					})
					this.addjson.knowledgeAttributionRelationList = newlist
					knowledgeadd(this.addjson).then((res) => {
						this.loading = false
						this.$router.back()
					}).catch((error) => {
						this.loading = false
					})
				}
			},
			parentchange(value) { //父节点发生选中的时候
				if (value == null) { //如果值为空  则显示下方单元和分类选择
					this.addjson.parentId = null
				} else { //如果不为空 则继承父级单元和分类
					this.addjson.parentId = value[value.length - 1]
				}
			},
			backpath() {
				this.$router.back()
			},
			removeitem(data) {
				let newlist = this.addjson.knowledgeAttributionRelationList.filter(item => item.id !== data.id);
				this.addjson.knowledgeAttributionRelationList = newlist
				this.$refs.tableref.toggleRowSelection(data)
			},
			tablechange(data) {
				this.addjson.knowledgeAttributionRelationList = data
			},
			getrowkey(row) {
				return row.id
			},
			getunitlist() {
				this.dyloading = true
				unitlist(this.queryjson).then((res) => {
					this.unitlist = res.data.list
					this.queryjson.pages = res.data.pages
					this.dyloading = false
				})
			},
			getgradeslist() {
				gradeslist({
					pageNum: 1,
					pageSize: 999999
				}).then((res) => {
					this.gradeslist = res.data.list
				})
			},
			getsubjectslist() {
				subjectslist({
					pageNum: 1,
					pageSize: 999999
				}).then((res) => {
					this.subjectslist = res.data.list
				})
			},
			getknowledgecategoreislist() {
				knowledgecategoreislist({
					pageNum: 1,
					pageSize: 999999
				}).then((res) => {
					this.knowledgecategoreislist = res.data.list
				})
			},
			gettree() {
				this.loadingall = true
				knowledgetree().then((res) => {
					this.treedata = res.data
					this.loadingall = false
				})
			},
			changetable() {
				this.queryjson.pageNum = 1
				this.getunitlist()
			},
			pagechange(index) {
				this.queryjson.pageNum = index
				this.getunitlist()
			}
		}
	}
</script>

<style scoped>
	.flex {
		display: flex;
	}

	.custom-tree-node {
		flex: 1;
		display: flex;
		align-items: center;
		justify-content: space-between;
		font-size: 14px;
		padding-right: 8px;
	}

	.rowtag {
		margin-top: 5px;
		margin-right: 5px;
	}

	.el-scrollbar {
		height: calc(100vh - 150px);
	}
</style>
